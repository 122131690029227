import React from "react"
import { Link, graphql } from "gatsby"

import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import PoolIcon from '@material-ui/icons/Pool';
import SwimPool from '../components/SwimPool'
import Grid from '@material-ui/core/Grid';

function IndexPage(data){
  const li = data.data.allDataYaml.nodes.map((node) =>
    <Grid item xs={3}>
    <SwimPool data={node}  />
    </Grid>
  );
 return (
  <>
  <CssBaseline />
  <AppBar position="static">
      <Toolbar>
        <IconButton onClick={() => {console.log('truc')}} >
          <PoolIcon />
        </IconButton>
        <Typography variant="h6"> Swim in Taipei</Typography>
      </Toolbar>
  </AppBar>
<Container maxWidth="sm" style={{textAlign: "center"}}>
    <Typography variant="h5" style= {{margin: "2vh"}}> Where to swim in Taipei ?</Typography>
    <Typography variant="body1"> Find the nearest public swimming pool</Typography>
</Container>

<Container maxWidth="xm" style={{marginTop:"2vh", marginBottom:"2vh", display: "flex", justifyContent:"center", alignItems: "flex-start"}}>
    <IconButton >
      <MyLocationIcon />
    </IconButton>
    <TextField style={{width: "40%"}} id="outlined-basic" variant="outlined" size="small" placeholder="Search"
          helperText="Outdoor ? Indoor ? Kids friendly ?" />
    <IconButton >
      <SearchIcon />
    </IconButton>
</Container>
<Container maxWidth="xm">
 <Grid container spacing={3} justify="center">
{li}
</Grid>
</Container>
    </>
)};

export const query = graphql`
  {
    allDataYaml {
      nodes {
        Name {
          En
        }
        Fees {
          FullPrice
        }
        Address {
          En
        }
      }
    }
  }`;

export default IndexPage
